<template>
    <div style="padding: 20px; background-color: #444B50; height: 90%;">
        <div style="font-size: 23px; color: white;">
            {{ order.userName }}
        </div>
        <div style="line-height: 80px; color:#F7941A; font-size: 25px;">
            您的预约已提交成功!
        </div>
        <div style="font-size: 20px; color: white;">
            以下是您的预约信息:
        </div>
        <div style="background-color: #FFFCF0; margin-top: 20px; border-radius: 20px; padding-top: 30px;">
            <el-image style="width: 60px; height: 60px;  border-radius: 50%;" :src="staff.staffLogo"></el-image>
            <div>{{ staff.staffName }}</div>
            <div style="font-size: 25px; font-weight: bold; line-height: 60px;">{{ order.reservationDatetime }}</div>
            <div style="font-size: 25px; font-weight: bold; line-height: 30px;">{{ order.reservationStaffTime }}</div>
            <div style="border-bottom: 1px solid #E2E1DE; width: 90%; padding-top: 20px;"></div>
            <div style="line-height: 30px; width: 80%; text-align: left; padding-left: 10%; padding-top: 20px;">
                <el-icon>
                    <Location />
                </el-icon>
                {{ company.companyLocation }}
            </div>
            <div style="line-height: 40px; width: 80%;  text-align: left; padding-left: 10%; margin-bottom: 20px;">
                <el-icon>
                    <Phone />
                </el-icon>
                {{ company.companyPhone }}
            </div>
        </div>
        <!-- <div style="line-height: 60px; height: 60px; background-color: #E9F0FD; margin-top: 20px; border-radius: 10px; 
             font-size: 21px; display: flex; justify-content: center; cursor: pointer;" :loading="loading" @click="synGoogleHandler">
            <img src="../../assets/gmail.png"
                style="width: 30px; height: 30px; margin-top: 12px; margin-right: 10px;" />
            <div>同步到 Google 日历</div>
        </div> -->
        <el-button style="line-height: 60px; height: 60px; background-color: #E9F0FD; margin-top: 20px; border-radius: 10px; 
        font-size: 21px; width: 100%;" :loading="loading" @click="synGoogleHandler">
            <img src="../../assets/gmail.png"
                style="width: 30px; height: 30px; margin-top: 0px; margin-right: 10px;" />
            同步到 Google 日历
        </el-button>
        <div style="height: 100%;"></div>
    </div>
</template>
<script>
import utils from '../../utils/Utils'
import { ElMessage } from 'element-plus'

export default {
    data() {
        return {
            companyId: '',
            order: null,
            staff: [],
            company: {},
            synMine: {},
            loading: false
        }
    },
    methods: {
        getStaff: function () {
            let that = this;
            this.axios.get(utils.baseUrl + 'reservationCompanyStaff/' + this.order.staffId).then((res) => {
                that.staff = res.data.data;
            })
        },
        getCompany: function () {
            let that = this;
            this.axios.get(utils.baseUrl + 'reservationCompany/getByNum/' + this.companyId).then((res) => {
                that.company = res.data.data;
            })
        },
        getMine: function () {
            let that = this;
            this.axios.get(utils.baseUrl + 'reservationMine/get/' + this.$route.params.mineId).then((res) => {
                that.synMine = res.data.data
            })
        },
        synGoogleHandler: function () {
            let that = this;
            this.loading = true;
            const event = {
                'summary': this.synMine.projectName,
                'location': '800 Howard St., San Francisco, CA 94103',
                'description': this.synMine.reservationCommit,
                'start': {
                    'dateTime': this.synMine.reservationDatetime + 'T' + this.synMine.reservationStaffTime + ':00-04:00',
                },
                'end': {
                    'dateTime': this.synMine.reservationDatetime + 'T' + this.synMine.reservationEndtime + ':00-04:00',
                },
            };
            window.handleAuthClick(event, function () {
                ElMessage({ type: 'success', message: '预约信息已同步至您的日历!', })
                that.loading = false;
                // that.$router.push({ path: '/usr/index/'+that.companyId})
            })
        }
    },
    created() {
        this.companyId = this.$route.params.companyId; // 获取动态片段
        this.order = JSON.parse(localStorage.getItem('order'))
        this.getStaff()
        this.getCompany()
        this.getMine()
    }
}
</script>
<style scoped>
body{
    margin: 0px;
}
</style>