import {createI18n} from 'vue-i18n'
import zh from './zh'
import en from './en'

const language = (navigator.language || 'en').toLocaleLowerCase();

const i18n = createI18n({
    legacy: false,
    locale: localStorage.getItem('lang') || language,
    fallbackLocale: 'en',
    globalInjection: true,
    messages: {
        en,
        zh
    }
})
export default i18n;