<template>
    <div>
        <div style="height: 30px; background-color: #444B50; width: 100%; text-align: left;">
            <el-icon style="color: white; padding-top: 10px; padding-left: 10px;" size="20">
                <ArrowLeftBold @click="backHandler"/>
            </el-icon>
        </div>
        <div style="background-color: #444B50; height: 100px; padding: 20px; display: flex; flex-direction: row;">
            <div style="display: flex; flex-direction: column;">
                <el-upload class="avatar-uploader" :auto-upload="true" list-type="picture-card"
                    :before-upload="beforeAvatarUpload">
                    <img v-if="staff.staffLogo" :src="staff.staffLogo" class="avatar" />
                    <el-icon v-else class="avatar-uploader-icon">
                        <Plus />
                    </el-icon>
                </el-upload>
                <div style="color: white; margin-top: 10px;">
                    {{ staff.staffName }}
                </div>
            </div>
            <div style="width: 30px;"></div>
            <div style="background-color: #8F9395; color: white; width: 220px; padding: 10px; height: 90px; ">
                <div style="width: 100%; text-align: left;">服务项目</div>
                <div v-for="project in projects" :key="project.projectId" style="font-size: 13px; display: flex;">
                    <div
                        style="background-color: #F7941A; width: 10px; height: 10px; margin-top: 5px; border-radius: 50%; ">
                    </div>
                    <div style="margin-left: 10px; ">{{ project.projectName }}</div>
                </div>
            </div>
        </div>
        <div style="padding: 15px;">
            <div style="background-color: #F1F1F1; margin-top: 30px; padding: 20px;">
                <div style="font-weight: bold; font-size: 14px;">变更邮箱</div>
                <el-input v-model="staff.staffEmail" size="large"></el-input>
                <div style="height: 10px;"></div>
                <el-button style="background-color: #444B50; width: 100%; color: white; " size="large" @click="saveEmailHandler">保存</el-button>
                <!-- <div style="font-size: 13px; margin-top: 10px;">点击变更确认邮件中的连接后生效</div> -->
            </div>
            <div style="background-color: #F1F1F1; margin-top: 30px; padding: 20px;">
                <div style="font-weight: bold; font-size: 14px;">修改密码</div>
                <el-form label-width="100px">
                    <el-form-item label="新密码">
                        <el-input v-model="staff.staffPwd" type="password" maxlength="100" />
                    </el-form-item>
                    <el-form-item label="确认新密码">
                        <el-input v-model="staff.staffConPwd" type="password" maxlength="100" />
                    </el-form-item>
                </el-form>
                <div style="height: 10px;"></div>
                <el-button style="background-color: #444B50; width: 100%; color: white; " size="large"
                    @click="updatePwdHandler">修改密码</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import utils from '../../utils/Utils'
import { ElMessage } from 'element-plus'


export default {
    data() {
        return {
            staff: {},
            projects: []
        }
    },
    methods: {
        beforeAvatarUpload: function (file) {
            let that = this;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = e => {
                // e.target.result 就是Base64字符串
                that.staff.staffLogo = e.target.result
                // 这里可以将Base64字符串传递给其他方法或数据属性
                that.saveStaffHandler()
            };
            // 阻止默认上传行为
            return false;
        },
        getStaffServices: function () {
            let that = this;
            this.axios.get(utils.baseUrl + 'reservationProjectStaff/getByStaffId/' + this.staff.staffId).then((res) => {
                if (res.data.code === 200) {
                    that.projects = res.data.data;
                }
            })
        },
        saveStaffHandler: function () {
            this.axios.post(utils.baseUrl + 'reservationCompanyStaff/update', this.staff).then((res) => {
                if (res.data.code === 200) {
                    ElMessage({ type: 'success', message: '头像更新成功!', })
                }
            })
        },
        updatePwdHandler: function () {
            if (this.staff.staffPwd === '' || this.staff.staffPwd === undefined) {
                ElMessage.error('请输入密码!')
                return
            }
            if (this.staff.staffPwd != this.staff.staffConPwd) {
                ElMessage.error('两次密码不一致!')
                return
            }
            this.staff.staffPassWord = this.staff.staffPwd
            this.axios.post(utils.baseUrl + 'reservationCompanyStaff/update', this.staff).then((res) => {
                if (res.data.code === 200) {
                    ElMessage({ type: 'success', message: '密码更新成功!', })
                }
            })
        },
        backHandler: function () {
            this.$router.go(-1)
        },
        saveEmailHandler: function () {
            this.axios.post(utils.baseUrl + 'reservationCompanyStaff/update', this.staff).then((res) => {
                if (res.data.code === 200) {
                    ElMessage({ type: 'success', message: '邮箱更新成功!', })
                }
            })
        }
    },
    created() {
        this.staff = JSON.parse(localStorage.getItem('staff'))
        this.getStaffServices()
    }
}
</script>

<style>
body{
    margin: 0px;
}
.avatar {
    width: 50px;
    height: 50px;
}

.avatar-uploader .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
    width: 60px !important;
    height: 60px !important;
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}
</style>