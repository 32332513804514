export default {
    login: {
        login: '登录',
        userName: '您的邮箱',
        password: '登录密码',
    },
    user:{
        today: '今天'
    },
    staff:{
        today: '今天'
    }
};