<template>
    <div style="width: 100%; display: flex; justify-content: center;">
        <el-container>
            <el-header style="background-color: #444B50; height: 70px;">
                <div style="display: flex; padding-left: 15%; padding-right: 15%; height: 70px;">
                    <img style="width: 50px; height: 50px; border-radius: 50%; margin-top: 10px;"
                        :src="company.companyLogo">
                    <div style="line-height: 70px; width: 400px; color: white; font-weight: bold; ">{{
                        company.companyName }}</div>
                    <div style="width: 100%;"></div>
                    <el-icon style="width: 35px; height: 35px; margin-top: 15px; color: white; font-weight: bold; "
                        size="25">
                        <!-- <Setting @click="setHandler" /> -->
                        <img src="../assets/icon_setting_white_35x35.png" @click="setHandler">
                    </el-icon>
                    <div style="width:20px;"></div>
                    <el-icon style="width: 35px; height: 35px; margin-top: 15px; color: white;" size="25">
                        <!-- <Unlock @click="logouHandler" /> -->
                        <img src="../assets/icon_exit_white_35x35.png" @click="logouHandler">
                    </el-icon>
                </div>
            </el-header>
            <el-main style="display: flex; justify-content: center; padding: 0px">
                <div style="width: 80%;">
                    <el-row>
                        <el-col :span="6" style="background-color: #ECECEC; height: 825px; padding: 10px;">
                            <div
                                style="background-color: #ECECEC; width: 100%; height: 40px; display: flex; flex-direction: row; 
                                padding-top: 10px; margin-top: 20px; line-height: 40px; justify-content: space-between; ">
                                <div style="width: 100px; text-align: left;  font-weight: bold; ">人员列表</div>
                                <!-- <el-button type="primary" plain size="small" @click="allStaffVisibleHandler">全部</el-button> -->
                                <div style="display: flex; ">
                                    <div style="width: 20px;"></div>
                                    <img style="width: 30px; height: 30px; " src="../assets/icon_del_30x30.png"
                                        @click="getDelHandler" />
                                    <div style="width: 20px;"></div>
                                    <div style="border: none; border-radius: 50%; cursor: pointer; line-height: 30px; color: white; background-color: #FF9C1C; width: 30px; height: 30px;"
                                        @click="allStaffVisibleHandler">
                                        {{ staffs.length }}
                                    </div>
                                    <div style="width: 20px;"></div>
                                    <el-icon size="32" style="cursor: pointer;">
                                        <CirclePlus @click="addStaffVisibleHandler" />
                                    </el-icon>
                                </div>
                                <!-- <el-button type="primary" plain size="small" icon="CirclePlus" @click="addStaffVisibleHandler" /> -->
                            </div>
                            <div>
                                <!-- item -->
                                <div style="height: 30px;"></div>
                                <div v-for="(st, index) in staffs" :key="st.staffId" :id="'div' + index"
                                    style="display: flex; flex-direction: row; padding: 5px; cursor: pointer;"
                                    @mouseover="mouseOverEvent($event, index)" @mouseout="mouseOutEvent($event, index)"
                                    @click="selectStaffHandler($event, index)">
                                    <img v-if="st.staffLogo != null"
                                        style="width: 50px; height: 50px; border-radius: 50%;" :src="st.staffLogo" />
                                    <img v-else style="width: 50px; height: 50px; border-radius: 50%;"
                                        src="../assets/staff_headpic.png" />
                                    <div style="width: 20px;"></div>
                                    <div style="width: 100%; margin-top:5px;">
                                        <div style="width: 100%; text-align: left; font-size: 14px; font-weight: bold;">
                                            {{ st.staffName }}
                                        </div>
                                        <div
                                            style="width: 100%; text-align: left; font-size: 12px; height: 20px; line-height: 20px;">
                                            {{ st.staffEmail }}</div>
                                    </div>
                                    <!-- <el-button type="primary" icon="Edit" @click="editStaffHandler($event, index)" plain
                                        size="small" /> -->
                                    <img v-if="st.staffStatus == 0" src="../assets/icon_edit_30x30.png"
                                        :id="'img1' + index" style="width: 23px; height: 23px; display: none; "
                                        @click="editStaffHandler($event, index)" />
                                    <div style="display: inline; width: 20px; "></div>
                                    <!-- <el-button type="primary" icon="Delete" style="color: red;" plain size="small"
                                        @click="delStaffHandler($event, st.staffId)" /> -->
                                    <img v-if="st.staffStatus == 0" src="../assets/icon_del_30x30.png"
                                        :id="'img2' + index" style="width: 25px; height: 25px; display: none; "
                                        @click="delStaffHandler($event, st.staffId)" />
                                    <img v-if="st.staffStatus == 1" src="../assets/icon_restorestaff_30x30.png"
                                        :id="'img3' + index" style="width: 30px; height: 30px; display: none; "
                                        @click="backStaffHandler($event, index)" />

                                </div>
                            </div>
                            <!-- <div style="height: 100%;"></div> -->
                        </el-col>
                        <el-col :span="18">
                            <div style="width: 100%;">
                                <div
                                    style="width: 100%; height: 40px; line-height: 40px; text-align: right; font-weight: bold;">
                                    {{ tip }} 共有 {{ events.length }} 件预约
                                </div>
                                <vue-cal style="width: 100%; " :time-from="9 * 60" :time-to="19 * 60"
                                    :special-hours="specialHours" timeCellHeight="65" today-button :events="events"
                                    :disable-views="['years', 'year']" :showWeekNumbers="false" locale="zh-cn">
                                    <template #event="{ event }">
                                        <div @click="detailHandler(event)"
                                            :style="{ margin: customDayStyle.margin, borderRadius: customDayStyle.borderRadius, backgroundColor: event.color, cursor: 'pointer' }">
                                            <div
                                                style="display: flex; flex-direction: row; justify-content: space-between; ">
                                                <div style="font-size: 14px; text-align: left; line-height: 30px; "
                                                    v-html="event.title">
                                                </div>
                                                <img v-if="event.staffLogo != '' && event.staffLogo != null"
                                                    style="width: 25px; height: 25px; border-radius: 50%; "
                                                    :src="event.staffLogo" />
                                                <img v-else style="width:25px; height:25px;"
                                                    src="../assets/staff_headpic.png" />
                                            </div>
                                            <div
                                                style="display: flex; flex-direction: row; list-style: none; line-height: 25px;  ">
                                                <el-icon style="margin-top: 4px;">
                                                    <Warning />
                                                </el-icon>
                                                <div style="font-size: 11px; line-height: 25px; padding-left: 5px;">{{
                                                    event.reservationCommit }}</div>
                                            </div>
                                        </div>
                                    </template>
                                    <template #today-button="{}">
                                        <el-button size="small">{{ $t('user.today') }}</el-button>
                                    </template>
                                    <!-- <template #events-count="{ events }">
                                        <span v-if="customEventsCount(events)">
                                            {{ customEventsCount(events) }}
                                        </span>
                                    </template> -->
                                </vue-cal>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </el-main>
        </el-container>
    </div>
    <el-dialog v-model="dialogFormVisible" title="添加员工" width="600">
        <el-form :model="staff">
            <el-form-item label="姓名" :label-width="formLabelWidth">
                <el-input v-model="staff.staffName" autocomplete="off" placeholder="员工姓名" />
            </el-form-item>
            <el-form-item label="邮箱" :label-width="formLabelWidth">
                <el-input v-model="staff.staffEmail" autocomplete="off" placeholder="员工邮箱" />
            </el-form-item>
            <el-form-item label="密码" :label-width="formLabelWidth">
                <el-input v-model="staff.staffPassWord" autocomplete="off" minlength="8" placeholder="登录密码" />
            </el-form-item>
            <!-- <el-form-item label="职位" :label-width="formLabelWidth">
                <el-select v-model="services" placeholder="请选择职位">
                    <el-option v-for="service in services" :key="service.projectId" :label="service.projectName" :value="staff.projectId" />
                </el-select>
            </el-form-item> -->
            <el-form-item label="职位" :label-width="formLabelWidth">
                <el-scrollbar v-if="services.length != 0" height="200px" style="width: 100%">
                    <el-checkbox v-for="service in services" :key="service.projectId" :checked="service.checked"
                        v-model="service.checked" :label="service.projectName" size="large" border
                        style="width: 100%; margin-top:10px; " @change="changeHandler(service.projectId)" />
                </el-scrollbar>
                <div style="width: 100%; text-align: center;" v-else>
                    <el-empty :image-size="200" description="暂无职位信息，请先添加职位！" />
                </div>
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="dialogFormVisible = false">Cancel</el-button>
                <el-button type="primary" @click="addStaffHandler">Confirm</el-button>
            </span>
        </template>
    </el-dialog>

    <el-dialog v-model="setVisbile" title="系统设置" width="900" @close="closeHandler">
        <SettingView></SettingView>
    </el-dialog>
    <el-dialog v-model="detailVisible" title="" style="background-color: #FFFFFF; box-shadow: 5px 5px 5px 5px #888888;"
        width="450" @close="closeHandler">
        <div style="display: flex;color: #626567;">
            <div style="width:15px; margin-top: 5px; height:15px; background-color: #069BE5; border-radius: 3px; ">
            </div>
            <div style="margin-left: 30px;">
                <div style="width: 100%; text-align: left; font-size: 19px; ">{{ selectEvent.projectName }}</div>
                <div style="width: 100%; text-align: left;">{{ selectEvent.reservationDatetime }} {{
                    selectEvent.reservationStaffTime }} - {{ selectEvent.reservationEndtime }}</div>
            </div>
        </div>
        <div style="display: flex;color: #626567; height: 30px; margin-top: 10px;">
            <el-icon style="margin-top: 5px; color: #6B6F73;" size="20">
                <ScaleToOriginal />
            </el-icon>
            <div style="width: 100%; text-align: left; line-height: 30px; font-size: 14px; margin-left: 30px;">
                {{ selectEvent.reservationCommit }}<span style="color: #888888">(客户备注)</span></div>
        </div>
        <div style="display: flex;color: #626567; height: 30px; ">
            <el-icon style="margin-top: 5px;  color: #6B6F73;" size="20">
                <ChatDotRound />
            </el-icon>
            <div style="width: 100%; text-align: left; line-height: 30px; font-size: 14px; margin-left: 30px;">
                {{ selectEvent.staffCommit }}<span style="color: #888888">(员工备注)</span></div>
        </div>
        <div style="display: flex;color: #626567; height: 30px;  margin-top: 10px;">
            <el-icon style="margin-top: 5px;  color: #6B6F73;" size="20">
                <User />
            </el-icon>
            <div style="width: 100%; text-align: left; line-height: 30px; font-size: 14px; margin-left: 30px;">
                {{ selectEvent.staffName }}<span style="color: #888888">(员工姓名)</span></div>
        </div>
        <div style="border-bottom: 1.5px solid #6B6F73; margin-top: 10px; "></div>
        <div style="display: flex;color: #626567; height: 30px;  margin-top: 10px;">
            <el-icon style="margin-top: 5px;  color: #6B6F73;" size="20">
                <User />
            </el-icon>
            <div style="width: 100%; text-align: left; line-height: 30px; font-size: 14px; margin-left: 30px;">
                {{ selectEvent.userName }}<span style="color: #888888">(客户姓名)</span></div>
        </div>
        <div style="display: flex;color: #626567; height: 30px; ">
            <el-icon style="margin-top: 5px;  color: #6B6F73;" size="20">
                <Message style="color: #6B6F73;" />
            </el-icon>
            <div style="width: 100%; text-align: left; line-height: 30px; font-size: 14px; margin-left: 30px;">
                {{ selectEvent.userEmail }}<span style="color: #888888">(客户邮箱)</span></div>
        </div>
    </el-dialog>
</template>
<script>
import VueCal from 'vue-cal'
import SettingView from './SettingView.vue'
import utils from '../utils/Utils'
import 'vue-cal/dist/vuecal.css'
import { ElMessage, ElMessageBox } from 'element-plus'

// import 'vue-cal/dist/i18n/zh-cn.js'
const dailyHours = { from: 9 * 60, to: 18 * 60, class: 'business-hours' }
export default {
    components: { VueCal, SettingView },
    props: {
        msg: String,
    },
    data() {
        return {
            services: [],
            message: 'Hello Vue!',
            dialogFormVisible: false,
            setVisbile: false,
            specialHours: {
                1: dailyHours,
                2: dailyHours,
                3: [
                    { from: 9 * 60, to: 12 * 60, class: 'business-hours' },
                    { from: 14 * 60, to: 18 * 60, class: 'business-hours' }
                ],
                4: dailyHours,
                5: dailyHours
            },
            staff: {},
            staffs: [],
            count: 0,
            company: {},
            events: [],
            customDayStyle: {
                margin: '5px',
                borderRadius: '10px',
            },
            currentElementId: '',
            detailVisible: false,
            selectEvent: {},
            defaultLogo: '../assets/logo.png',
            tip: '所有人员',
            status: 0
        };
    },
    methods: {
        /**
         * 显示员工添加页面
         */
        addStaffVisibleHandler: function () {
            this.dialogFormVisible = true;
            this.services.forEach(element => {
                element.checked = false;
            });
            this.staff = {}
        },
        /**
         * 添加员工业务逻辑
         */
        addStaffHandler: function () {
            let that = this;
            let projects = [];
            this.services.forEach(element => {
                if (element.checked) {
                    projects.push(element.projectId);
                }
            });
            this.staff.projects = projects.toString();
            this.staff.companyId = this.company.companyId;
            let url = utils.baseUrl + 'reservationCompanyStaff/add'; // 添加
            if (this.staff.staffId != null) {
                url = utils.baseUrl + 'reservationCompanyStaff/update'; // 编辑
            }
            this.axios.post(url, this.staff).then((res) => {
                if (res.data.code === 200) {
                    that.dialogFormVisible = false;
                    that.getStaffList()
                } else {
                    ElMessage({ type: 'error', message: res.data.message })
                }
            })
        },
        /**
         * 编辑员工信息
         */
        editStaffHandler: function (event, index) {
            this.dialogFormVisible = true
            this.staff = this.staffs[index]

            // 设置可选项
            for (let index = 0; index < this.services.length; index++) {
                const element = this.services[index];
                this.services[index].checked = false;

                for (let j = 0; j < this.staff.projectList.length; j++) {
                    const staffService = this.staff.projectList[j];

                    if (Number(element.projectId) === Number(staffService.projectId)) {
                        this.services[index].checked = true;
                    }
                }
            }
            event.stopPropagation();

        },
        /**
         * 删除员工信息
         */
        delStaffHandler: function (event, staffId) {
            event.stopPropagation();
            ElMessageBox.confirm(
                '确认屏蔽当前员工信息吗?',
                'Warning',
                { confirmButtonText: 'OK', cancelButtonText: 'Cancel', type: 'warning', center: true, }
            )
                .then(() => {
                    ElMessage({ type: 'success', message: 'Delete completed', })
                    let that = this;
                    this.axios.get(utils.baseUrl + 'reservationCompanyStaff/del/' + staffId).then(() => {
                        that.getStaffList()
                    })

                })
                .catch(() => {
                    ElMessage({ type: 'info', message: 'Delete canceled', })
                })
        },
        setHandler: function () {
            // this.$router.push('/setting')
            localStorage.setItem('company', JSON.stringify(this.company))
            this.setVisbile = true
        },
        /**
         * 获取业务列表
         */
        getProjectsHandler: function () {
            let that = this;
            let companyId = this.$route.params.companyId;
            this.axios.get(utils.baseUrl + 'reservationProject/getByCompanyId/' + companyId).then((res) => {
                that.services = res.data.data;
                that.services.forEach(el => {
                    el.checked = false
                })
            })
        },
        /**
         * 获取当前公司员工列表
         */
        getStaffList: function () {
            let that = this;
            let companyId = this.$route.params.companyId;
            this.axios.get(utils.baseUrl + 'reservationCompanyStaff/list/' + companyId + "/0").then((res) => {
                that.staffs = res.data.data
                console.log('res.data.data - ', res.data.data)

                that.getEvents()
            })
        },

        mouseOverEvent: function (event, index) {
            // this.currentElementId = event.currentTarget.id;
            if (document.getElementById('img1' + index) != null) document.getElementById('img1' + index).style.display = 'block';
            if (document.getElementById('img2' + index) != null) document.getElementById('img2' + index).style.display = 'block';
            if (document.getElementById('img3' + index) != null) document.getElementById('img3' + index).style.display = 'block';
        },
        mouseOutEvent: function (event, index) {
            // document.getElementById(event.currentTarget.id).style.backgroundColor = '#ECECEC';
            if (document.getElementById('img1' + index) != null) document.getElementById('img1' + index).style.display = 'none';
            if (document.getElementById('img2' + index) != null) document.getElementById('img2' + index).style.display = 'none';
            if (document.getElementById('img3' + index) != null) document.getElementById('img3' + index).style.display = 'none';
        },
        logouHandler: function () {
            this.$router.push('/')
        },
        /**
         * 获取公司信息
         */
        getCompany: function () {
            let that = this;
            let companyId = this.$route.params.companyId; // 获取动态片段
            this.axios.get(utils.baseUrl + 'reservationCompany/get/' + companyId).then((res) => {
                that.company = res.data.data

                if (that.company.companyIdnum != that.$route.params.num) {
                    ElMessageBox.alert('当前登录信息错误，请重新登录', '标题', {
                        confirmButtonText: 'OK',
                        callback: () => {
                            that.$router.push('/')
                        },
                    })
                }
            })
        },
        closeHandler: function () {
            this.getCompany()
            this.getProjectsHandler();
        },
        changeHandler: function (value) {
            console.log('value - ', value)
        },
        /**
         * 获取日历事件信息
         */
        getEvents: function () {
            let that = this;
            let companyId = this.$route.params.companyId;
            this.axios.get(utils.baseUrl + 'reservationMine/list/' + companyId).then((res) => {
                for (let index = 0; index < res.data.data.length; index++) {
                    const element = res.data.data[index];

                    element.start = element.reservationDatetime + ' ' + element.reservationStaffTime;
                    element.end = element.reservationDatetime + ' ' + element.reservationEndtime;
                    element.title = element.projectName;
                    element.color = utils.getColor(index);

                    that.staffs.forEach(item => {
                        if (Number(item.staffId) === Number(element.staffId)) {
                            element.staffLogo = item.staffLogo
                            element.staffName = item.staffName
                        }
                    })
                }
                that.events = res.data.data
                console.log('that.events - ', that.events)
            })
        },
        selectStaffHandler: function (event, index) {
            let that = this;
            let companyId = this.$route.params.companyId;
            this.axios.get(utils.baseUrl + 'reservationMine/staff/' + this.staffs[index].staffId + '/' + companyId,).then((res) => {
                that.events = res.data.data
                for (let index = 0; index < res.data.data.length; index++) {
                    const element = res.data.data[index];

                    element.start = element.reservationDatetime + ' ' + element.reservationStaffTime;
                    element.end = element.reservationDatetime + ' ' + element.reservationEndtime;
                    element.title = element.projectName;
                    element.color = utils.getColor(index);

                    that.staffs.forEach(item => {
                        if (Number(item.staffId) === Number(element.staffId)) {
                            element.staffLogo = item.staffLogo
                            element.staffName = item.staffName
                        }
                    })
                }
            })
            // 设置选中状态
            if (this.currentElementId != '') {
                document.getElementById(that.currentElementId).style.backgroundColor = '#ECECEC';
            }
            that.currentElementId = event.currentTarget.id;
            document.getElementById(that.currentElementId).style.backgroundColor = '#FFFFFF';
            this.tip = this.staffs[index].staffName;
        },
        allStaffVisibleHandler: function () {
            this.tip = '所有人员'
            this.getEvents()
        },
        customEventsCount: events => {
            return events ? events.filter(e => e.class === 'leisure').length : 0
        },
        detailHandler: function (event) {
            this.detailVisible = true;
            this.selectEvent = event;
            console.log(event)
        },
        /**
         * 获取已屏蔽的员工列表
         */
        getDelHandler: function () {

            let tmpStatus = 0;
            if (this.status === 0) {
                tmpStatus = 1;
                this.status = 1;
            } else {
                tmpStatus = 0;
                this.status = 0;
            }
            let that = this;
            let companyId = this.$route.params.companyId;
            this.axios.get(utils.baseUrl + 'reservationCompanyStaff/list/' + companyId + '/' + tmpStatus).then((res) => {
                that.staffs = res.data.data
                console.log('res.data.data - ', res.data.data)
            })
        },
        backStaffHandler: function ($event, index) {
            // let that = this;
            ElMessageBox.confirm('确认恢复当前员工信息吗?', 'Warning',
                { confirmButtonText: 'OK', cancelButtonText: 'Cancel', type: 'warning', center: true, }
            )
                .then(() => {
                    this.staffs[index].staffStatus = 0;
                    this.axios.post(utils.baseUrl + 'reservationCompanyStaff/update', this.staffs[index]).then(() => {
                        ElMessage({ type: 'success', message: 'Delete completed', })
                    })
                })
                .catch(() => {
                    ElMessage({ type: 'info', message: 'Delete canceled', })
                })
        }
    },
    mounted() {
    },
    beforeMount() {
        this.getStaffList()
        this.getCompany()
        this.getProjectsHandler();
        // this.getEvents()
    }
}
</script>
<style>
body {
    margin: 0px;
}

.vuecal--month-view .vuecal__cell {
    height: 90px;
}

.vuecal--month-view .vuecal__cell-content {
    justify-content: flex-start;
    height: 100%;
    align-items: flex-end;
}

.vuecal--month-view .vuecal__cell-date {
    padding: 4px;
}

.vuecal--month-view .vuecal__no-event {
    display: none;
}
</style>