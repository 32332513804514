// let baseUrl = "http://localhost:8088/booking/"
let baseUrl = "https://www.booking1s.com/booking/"
let staUrl = "https://www.booking1s.com/"


function getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}
function getColor(index) {
    
    let dic = {
        0 : '#F5F5F5',
        1 : '#FEF0C7',
        2 : '#E0EAFF',
        3 : '#F7E8FF',
    }
    return dic[index];
}

export default {
    baseUrl: baseUrl,
    staUrl: staUrl,
    getRandomColor: getRandomColor,
    getColor: getColor
}
