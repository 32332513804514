import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/ReservationHome.vue'
import SettingView from '../views/SettingView.vue'
import LoginView from '../views/LoginView.vue'
import IndexView from '../views/user/IndexView.vue'
import InfoView from '../views/user/InfoView.vue'
import ResultView from '../views/user/ResultView.vue'
import StaffView from '../views/staff/StaffView.vue'
import StaffLogin from '../views/staff/StaffLogin.vue'
import staffSet from '../views/staff/staffSet.vue'


const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/setting',
    name: 'setting',
    component: SettingView
  },
  {
    path: '/home/:companyId/:num',
    name: 'home',
    component: HomeView
  },
  {
    path: '/usr/index/:companyId',
    name: 'index',
    component: IndexView
  },{
    path: '/result/:companyId/:mineId',
    name: 'result',
    component: ResultView
  },{
    path: '/info/:companyId',
    name: 'info',
    component: InfoView
  },{
    path: '/staff/:companyId',
    name: 'staff',
    component: StaffView
  },{
    path: '/staffLogin/:companyId',
    name: 'staffLogin',
    component: StaffLogin
  },{
    path: '/staffSet',
    name: 'staffSet',
    component: staffSet
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
